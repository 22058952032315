.errContainer {
  display: flex;
  justify-content: space-around;
  padding-top: 0;
  padding-bottom: 0;
  height: 100vh;
  width: 100%;
  flex-grow: 1;
}

.errorTextSide {
  margin-top: auto;
  margin-bottom: auto;
}

.errorImage {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.errorImage img {
  width: auto;
  height: auto;
  max-width: 280px;
  object-fit: contain;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.errTextWrapper {
  margin-top: 8px;
  margin-bottom: 24px;
}

@media screen and (max-width: 640px) {
  .errContainer {
    flex-direction: column;
    row-gap: 48px;
    justify-content: center;
  }

  .errorTextSide {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
  }

  .errorImage {
    flex-direction: row-reverse;
  }

  .errorImage img:nth-child(1) {
    transform: rotate(90deg);
    width: 35%;
  }

  .errorImage img:nth-child(2) {
    transform: rotate(90deg);
    width: 35%;
  }

  .errTextWrapper {
    margin-bottom: 16px;
  }

  .errDescription {
    text-align: center;
  }

  .errTitle {
    text-align: center;
    font-size: 21px;
    line-height: 24px;
  }
}
