.cardItem {
  padding: 24px;
  background: #FFFFFF;
  border-radius: 8px;;
  width: 100%;
  display: flex;
  gap: 15px;
}

.skeleton {
  border-radius: 8px;

  &__icon {
    border-radius: 50%;
    height: 42px;
  }

  &__firstTitle {
    border-radius: 8px;
    margin-top: 8px;
    height: 30px;


  }

  &__tag {
    border-radius: 8px;
    height: 20px;
  }

  &__adress {
    margin-top: 10px;
    border-radius: 8px;
    height: 10px;
  }

  &__info {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}

.skeletonSecondTitle {
  margin-bottom: 24px;
}

.skeletonContactContainer {
  display: flex;
  column-gap: 16px;
  margin-bottom: 24px;
}

.cardItem {
  padding: 24px;
  background: #FFFFFF;
  border-radius: 24px;;
  width: 100%;
  display: flex;

  &__grid {
    width: 100%;

    &_item {
      width: 100%;
      height: 100%;

    }
  }

  &__status {
    display: flex;
    gap: 10px;
    margin-bottom: 16px;
    flex-direction: row-reverse;
  }
}

@media screen and (max-width: 768px) {

  .cardItem {
    max-width: 400px;
  }

  .skeleton {

    &__firstTitle {
      height: 20px;
    }
  
    &__tag {
      height: 25px;
    }
  
    &__adress {
      margin: 20px 0 20px 0;
      border-radius: 8px;
      height: 10px;
    }
  
    &__btn {
      margin-top: 8px;
      border-radius: 8px;
      height: 45px;
    }
  }
}
