/* Указываем box sizing */
*,
*::before,
*::after {
  box-sizing: border-box;
}

*:focus-visible {
  outline-color: #00B2A9;
}

h1, h2, h3, h4 {
  font-family: 'Roboto', sans-serif;
}

ul,
ol {
  padding: 0;
  list-style: none;
}

/* Убираем внешние отступы */
body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

/* Выставляем основные настройки по-умолчанию для body */
body {
  font-family: 'Roboto';
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;

  background-color: #F5F5F5;
}

span {
  font-family: 'Roboto', sans-serif;
}

body.no-scroll {
  overflow: auto;
}

/* Элементы a, у которых нет класса, сбрасываем до дефолтных стилей */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

a {
  color: inherit;
  text-decoration: none;
}

/* Упрощаем работу с изображениями */
img {
  max-width: 100%;
  display: block;
}

/* Указываем понятную периодичность в потоке данных у article*/
article > * + * {
  margin-top: 1em;
}

/* Наследуем шрифты для инпутов и кнопок */
input,
button,
textarea,
select {
  font: inherit;
}

.Typography {
  font-family: 'Roboto', sans-serif;
}

.searchObjectConnect .Input-Fieldset {
  border-radius: 20px;
}

.searchObjectConnect {
  width: 100%;
  max-width: 460px;
}
