.connectionModal {
  padding: 24px;
  border: none;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  width: 100%;
  max-width: 500px;
  overflow: hidden;
}

.modalContainer {
  margin: 16px;
}

.info {
  background: #F0F5FF;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  border-radius: 8px;
  padding: 12px 16px;
  gap: 15px;
  margin-bottom: 20px;

  &__item {
    display: flex;
    justify-content: space-between;
  }
}

.options {
  list-style: disc;
  margin-left: 16px;
  margin-top: 10px;
}

.modalCloseBtn {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;

  &:hover {
    background-color: transparent;
  }
}
