.typeBio {
  margin: 40px 0;


  &__wrp {
    display: flex;
    flex-wrap: wrap;
    gap: 17px;
  }

  &__block {
    padding: 24px;
    background-color: #FFFFFF;
    border-radius: 24px;
    display: flex;
    gap: 12px;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;


    &_info {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

}

.skeleton {

  &__title {
    border-radius: 4px;
  }

  &__icon{
    border-radius: 8px;
  }

  &__status{
    border-radius: 4px;
  }
}

.d {
  color: red !important;

    & .Input-Fieldset {
      border-radius: 20px;
    }

}
