.connectionModal {
    padding: 24px;
    border: none;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    width: 100%;
    max-width: 500px;
    overflow: hidden;
}

.modalContainer {
    margin: 16px;
}

.modalCloseBtn {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0;

    &:hover {
        background-color: transparent;
    }
}

.connectionModalButtons {
    display: flex;
    justify-content: flex-end;
    column-gap: 12px;
}

@media screen and (max-width: 640px) {

    .modalCloseBtn {
        top: 0;
        right: 0;
    }
}

.cardItem {
    padding: 24px;
    background: #FFFFFF;
    border-radius: 8px;;
    width: 100%;
    display: flex;
    flex-direction: column;
    max-height: 90vh;
}

.cardItem:last-child {
    margin-right: 0;
    margin-bottom: 0;
}

.imageContainer {
    max-height: 48px;
    width: auto;
    height: auto;
    margin-bottom: 16px;
}

.contacts {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.contacts__item {
    display: flex;
    align-items: center;
    column-gap: 16px;
    max-height: 300px;
    overflow-y: scroll;
}

.contacts__grid {
    display: grid;
    column-gap: 16px;
    grid-template-columns: max-content auto;
    align-items: center;
}

.contacts__gridItem {
    grid-column: 2/3;
}

.contacts__list {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    margin-bottom: 40px;
}

@media screen and (max-width: 640px) {
    .cardItem {
        padding: 16px;
    }

    .imageContainer {
        margin-bottom: 12px;
    }
}
