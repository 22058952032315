.cardItem {
  padding: 24px;
  background: #FFFFFF;
  border-radius: 24px;
  ;
  width: 100%;
  display: flex;

  &__grid {
    width: 100%;

    &_item {
      height: fit-content;
    }
  }

  &__status {
    display: flex;
    gap: 10px;
    margin-bottom: 16px;
    flex-direction: row-reverse;
  }
}

.cardItem:last-child {
  margin-right: 0;
  margin-bottom: 0;
}

.imageContainer {
  max-height: 90px;
  width: auto;
  height: auto;
}

.contacts {
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 20px;

  &__notPermission {
    width: 100%;
    padding: 10px;
    border-radius: 7px;
    background-color: rgb(255, 206, 21);
    ;
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.contacts__item {
  display: flex;
  align-items: center;
  column-gap: 16px;
}

.contacts__grid {
  display: grid;
  column-gap: 16px;
  grid-template-columns: max-content auto;
  align-items: center;
}

.contacts__gridItem {
  display: flex;
  grid-column: 2/3;
}

.contacts__morePhone {
  color: #9A9A9A;
  padding: 0 0 0 10px;
}

.flexStart {
  align-items: flex-start;
}

.expandedBtn {
  align-self: flex-start;
  margin-top: 8px;
  margin-bottom: 20px;
}

.contacts__list {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

@media screen and (max-width: 768px) {
  .imageContainer {
    margin-bottom: 12px;
  }
}

@media screen and (max-width: 1150px) {
  .btn {
    max-width: 100%;
    margin-right: auto;

    & span {
      white-space: pre-wrap;
    }
  }


  .cardItem {
    padding: 24px;
    background: #FFFFFF;
    border-radius: 24px;
    ;
    width: 100%;
    display: flex;

    &__grid {
      width: 100%;

      &_item {
        height: fit-content;
      }
    }

    &__status {
      display: flex;
      gap: 10px;
      margin-bottom: 16px;
      flex-direction: row;
    }
  }
}
