.container {
  padding-top: 32px;
  padding-bottom: 32px;
  display: flex;
  gap: 32px;
}

.suggestionContainer {
  background: linear-gradient(180deg, #544B61 7.13%, #231E2C 100%);
  margin-bottom: 80px;
  border-radius: 8px;
}

.suggestion {
  padding: 32px;
  background: url("../../assets/card_MIR_zabota.png") no-repeat 90% 100% / contain;

  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
}

.suggestion__header {
  margin-bottom: 16px;
}

.suggestion__text {
  margin-bottom: 24px;
  max-width: 508px;
}

.permissions {
  width: 100%;
}

.permissions__title {
  margin-bottom: 24px;
}

.permissions__filters {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  column-gap: 24px;
}

.permissions__search {
  width: 50%;
}

.permissions__sort {
  display: flex;
  column-gap: 24px;
  align-items: center;
}

.permissions__sortItems {
  display: flex;
  column-gap: 12px;
}

.permissions_sortItem input {
  display: none;
}

.permissions_sortItem input ~ div {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  background-color: #E8E8E8;
  border-radius: 24px;
  -moz-outline-radius: 24px;
  padding: 6px 16px;
  cursor: pointer;

  transition: background-color ease-in .15s, color ease-in .15s;

  &:hover {
    background-color: var(--color-background-secondary-hover);
  }
}

.permissions_sortItem input:checked ~ div {
  background-color: #00B2A9;
  color: #FFFFFF;

  &:hover {
    background-color: #00beb3;
  }
}

.cardList {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 32px;
  align-items: stretch;
  flex-direction: column;
  margin-bottom: 40px;
}

.notFilterFoundText {
  margin-bottom: 24px;
}

.notFoundContainer {
  margin-top: 6%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.errContainer {
  display: flex;
  justify-content: space-around;
  padding-top: 0;
  padding-bottom: 0;
  height: 100%;
  width: 100%;
  flex-grow: 1;
}

.errorTextSide {
  margin-top: auto;
  margin-bottom: auto;
}

.errorImage img {
  width: auto;
  height: 100%;
  object-fit: cover;
}

.errTextWrapper {
  margin-top: 8px;
  margin-bottom: 24px;
}

.errorImageMobile {
  display: none;
}

@media screen and (max-width: 940px) {
  .suggestion {
    background: none;
  }

  .permissions__filters {
    margin-bottom: 24px;

    flex-direction: column;
    row-gap: 24px;
  }

  .permissions__search {
    width: 70%;
  }
}

@media screen and (max-width: 640px) {
  .suggestionContainer {
    margin-bottom: 40px;
  }

  .container {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .suggestion {
    padding: 16px;
  }

  .suggestion__header {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
  }

  .suggestion__text {
    margin-bottom: 16px;
  }

  .permissions__title {
    font-size: 21px;
    line-height: 24px;
  }

  .permissions__search {
    width: 100%;
  }

  .permissions__sort {
    column-gap: 12px;
  }

  .permissions__sortItems {
    column-gap: 8px;
  }

  .cardList {
    gap: 16px;
  }

  .errContainer {
    flex-direction: column;
    row-gap: 48px;
    justify-content: center;
  }

  .errorTextSide {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
  }

  .errorImage {
    display: none;
  }

  .errorImageMobile {
    display: block;
  }

  .errorImageMobile img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .errTextWrapper {
    margin-bottom: 16px;
  }

  .errDescription {
    text-align: center;
  }

  .errTitle {
    text-align: center;
    font-size: 21px;
    line-height: 24px;
  }
}


// -----------KDW-----------

.kdwBannerContainer {
  background: radial-gradient(35.82% 17.57% at 26.69% 22.80%, rgba(0, 129, 80, 0.20) 0%, rgba(0, 0, 0, 0.00) 100%), radial-gradient(62.69% 46.60% at 10.61% 68.58%, rgba(0, 51, 55, 0.90) 0%, rgba(1, 49, 56, 0.00) 100%), linear-gradient(90deg, #005642 0%, rgba(1, 77, 53, 0.00) 100%), #104833 384.691px -238.57px / 73.747% 169.057% no-repeat;

  margin-bottom: 80px;
  border-radius: 8px;
  min-height: 488px;
  display: flex;
  overflow: hidden;
}

.kdwBanner {
  padding: 40px 30px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  &__title {
    margin-top: 68px;
    max-width: 560px;
  }

  &__subTitle {
    margin-top: auto;
    max-width: 560px;
  }

  &__button {
    margin-top: 30px;
  }

  background-image: url("../../assets/KDW/kdw_banner_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}



.payment {
  margin: 40px 0;
  width: fit-content;

  &__wrp {
    display: flex;
    flex-wrap: wrap;
    gap: 17px;
  }

  &__block {
    padding: 24px;
    background-color: #FFFFFF;
    border-radius: 24px;
    display: flex;
    gap: 12px;
  }

  &__info {
    display: flex;
    flex-direction: column;
    margin: 0;
  }

  &__wrp {
    display: flex;
    gap: 12px;
    margin-bottom: 15px;
  }
}

.skeleton {
  
  &__icon {
    border-radius: 8px;
  }

  &__satus {
    margin-top: 10px;
    border-radius: 8px;
  }

  &__title {
    border-radius: 8px;
  }
}

.modal {

  &__title {
    display: flex;
    gap: 15px;
    margin-bottom: 30px;
  }
}

.identMB_m {
  margin-bottom: 24px;
}



@media screen and (max-width: 640px) {
  .kdwBanner {

    &__title {
      margin-top: 52px;
      max-width: 560px;
    }

    &__subTitle {
      margin-top: auto;
      max-width: 560px;
    }

    &__button {
      margin-top: 30px;
    }
  }
}

@media screen and (max-width: 768px) {
  .permissions {
    width: 100%;
    padding-bottom: 50px;
  }

}
