.cardItem {
  padding: 24px;
  background: #FFFFFF;
  border-radius: 8px;;
  width: 100%;
  display: flex;
}

.skeleton {
  border-radius: 8px;
  height: 100%;

  &__icon {
    border-radius: 8px;
    height: 10%;
  }

  &__firstTitle {
    border-radius: 8px;
    margin-top: 8px;
    height: 30px;


  }

  &__tag {
    margin-top: 18px;
    border-radius: 8px;
    height: 30px;
  }

  &__adress {
    margin-top: 20px;
    border-radius: 8px;
    height: 10px;
  }

  &__btn {
    border-radius: 8px;
    height: 45px;
  }
}

.skeletonSecondTitle {
  margin-bottom: 24px;
}

.skeletonContactContainer {
  display: flex;
  column-gap: 16px;
  margin-bottom: 24px;
}

.cardItem {
  padding: 24px;
  background: #FFFFFF;
  border-radius: 24px;;
  width: 100%;
  display: flex;

  &__grid {
    width: 100%;

    &_item {
      width: 100%;
      height: 100%;

    }
  }

  &__status {
    display: flex;
    gap: 10px;
    margin-bottom: 16px;
    flex-direction: row-reverse;
  }
}

@media screen and (max-width: 768px) {

  .cardItem {
    max-width: 400px;
  }

  .skeleton {

    &__firstTitle {
      height: 20px;
    }
  
    &__tag {
      height: 25px;
    }
  
    &__adress {
      margin: 20px 0 20px 0;
      border-radius: 8px;
      height: 10px;
    }
  
    &__btn {
      margin-top: 8px;
      border-radius: 8px;
      height: 45px;
    }
  }
}
