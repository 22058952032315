.status {
  display: flex;
  gap: 10px;
  margin-bottom: 16px;
  flex-direction: row;
  width: max-content;
  margin: 0;
  white-space: pre-wrap;
  width: 100%;
  align-items: center;
}
