.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
  background: #F0F5FF;
  max-height: 100vh;
}

.main {
  flex-grow: 1;
  display: flex;
  // flex-direction: column;
  margin: auto;
  padding: 0px;
  overflow: hidden;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .main {
    flex-direction: column;
  }
}
