.container {
  width: 100%;
  max-width: 600px;
  margin: auto;
  padding: 50px 15px;
}

.wrapper {
  width: 100%;
  height: 100%;
  background-color: #F0F5FF;
}

.item {
  display: flex;
  background-color: #fff;
  border-radius: 24px;
  overflow: hidden;
  padding: 15px;
  gap: 16px;
  align-items: flex-start;
  cursor: pointer;

  & img {
    width: 56px;
  }

  &__info {
    display: flex;
    flex-direction: column;
  }
}

.help {
  background-color: #fff;
  border-radius: 24px;
  overflow: hidden;
  padding: 15px;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
}

.list {
  margin-top: 40px;

  &__title {
    display: flex;
    gap: 18px;
    align-items: flex-start;
    // max-width: 530px;
  }

  &__number {
    background-color: #F1E8FF;
    border-radius: 8px;
    padding: 3px 12px;
    color: #893EEC;
  }

  &__content {
    display: flex;
    flex-direction: column;
    margin-left: 50px;
    gap: 15px;
    margin-top: 20px;
  }

  &__subTitle {
    gap: 10px;
    display: flex;

    & a {
      color: #893EEC;
    }
  }

  &__imgWrapper {
    padding: 20px;
    border-radius: 14px;
    background-color: #F6F6F6;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-bottom: 0;
  }

  &__btn {
    width: min-content;
  }

  &__img {
    width: min-content;
    max-width: 240px;
  }

  &__subList {
    display: flex;
    gap: 10px;
  }
}

.footer {
  display: flex;
  margin-top: 40px;
  justify-content: space-between;
}

.btn {
  background-color: #893EEC;
  margin-top: 20px;
  border-radius: 8px;
  width: 100%;
}

.link {
  cursor: pointer;
  color: #893EEC;
}

@media screen and (max-width: 768px) {
  .list {
  
    &__content {
      margin-left: 0px;
    }
  }
}
