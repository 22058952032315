.login {
  background: url("assets/bg.jpg") no-repeat bottom center / cover,
    radial-gradient(38.57% 72.97% at 61.05% 63.94%, #C4F4FF 0%, #B1C8F4 100%);
  flex-grow: 1;
  display: flex;

}

.woman {
  position: absolute;
  bottom: 0;
  right: -20px;
  max-width: 774px;
  width: 100%;
}

.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
  padding: 0 16px;

  position: relative;
}

.login__card {
  padding: 32px;
  margin-top: 8px;
  max-width: 382px;
  background: #FFFFFF59;
  border-radius: 24px;
  z-index: 1;
}

.text_m {
  margin-bottom: 24px;
  color: #2C2931;
}

.authorBG {
  position: absolute;
  bottom: 33px;
}

.heading_xl {
  margin-bottom: 24px;
  color: #2C2931;
}

@media screen and (max-width: 1000px) {
  .login__card {
    background: #ffffffe9;
  }
}

@media screen and (max-width: 640px) {
  .container {
    margin-top: 74px;
    justify-content: flex-start;
    align-items: center;
  }

  .login__card {
    padding: 16px;
    background: #ffffffe9;
  }

  .heading_xl {
    margin-bottom: 24px;
  }

  .text_m {
    margin-bottom: 12px;
  }

  .logBtn {
    margin-top: 12px;
  }
}

.btn {
  background-color: #1F66FF;
  color: #ffffff;
  transition: opacity .3s ease-in-out;

  &:hover {
    opacity: .6;
    background-color: #1F66FF;
  }
}
