.connectionModal {
  padding: 24px;
  border: none;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  width: 100%;
  max-width: 500px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: flex-start;
}

.modalContainer {
  margin: 16px;
}

.modalCloseBtn {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;

  &:hover {
    background-color: transparent;
  }
}
.qrWrapper {
  display: flex;
  width: 100%;
  height: 300px;
  max-width: 300px;
  max-height: 300px;
  margin: 20px auto;
  background-color: #cdcdcd4d;
  justify-content: center;
  align-items: center;
  border: 1px solid grey;
  border-radius: 8px;
  overflow: hidden;
}

.btnWrapper {

  display: flex;
  width: 100%;
  max-width: 300px;
  margin: -10px auto 20px auto;
}

.modal {

  &__title {
    display: flex;
    gap: 15px;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 640px) {

  .connectionModal {
    gap: 10px;
  }

  .qrWrapper {
    max-width: 160px;
    max-height: 160px;
  }

  .modal {

    &__title {
      margin-bottom: 0;
    }
  }
}