.agreementError {
    position: fixed;
    z-index: 10;
    bottom: 0;
    left: 0;
    right: 0;
    min-height: 220px;
    max-height: 250px;
    background: #ffffffee;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    padding: 56px 32px 32px 32px;

    display: flex;
    flex-direction: row;
    align-items: flex-start;

    box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.08);

    img {
        margin-right: 60px;
    }
}

.textMb12 {
    margin-bottom: 12px;
}

@media screen and (max-width: 640px) {
    .agreementError {
        flex-direction: column;
        align-items: center;
        max-height: 350px;
        padding: 32px;
        gap: 10px;

        &__text {
            text-align: center;
        }

        img {
            margin-right: 0px;
        }
    }

}
