.history {
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__section {
    gap: 15px;
    display: flex;
    flex-direction: column;
  }

  &__list {
    cursor: pointer;
    transition: all .2s linear;

    &:hover {
      background: #ececec;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
  }

  &__event {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 24px;
    overflow: hidden;
  }

  &__eventItem {
    padding: 16px; 
    display: flex;
    align-items: center;
    gap: 15px;

  }

  &__eventInfo {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &__eventPay {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }

  &__list {
    border-bottom: 1px solid #E9E9E9;

    &:last-child {
      border-bottom: 0px;
    }
  }
}

.connectionModal {
  padding: 24px;
  border: none;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  width: 100%;
  max-width: 500px;
  overflow: hidden;
}

.modalContainer {
  margin: 16px;
}

.modalCloseBtn {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;

  &:hover {
    background-color: transparent;
  }
}

.info {
  background: #F0F5FF;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  border-radius: 8px;
  padding: 12px 16px;
  gap: 15px;
  margin-bottom: 20px;

  &__item {
    display: flex;
    justify-content: space-between;
  }
}

.modal {

  &__title {
    display: flex;
    gap: 15px;
    margin-bottom: 30px;
  }
}

.cardList {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.notFilterFoundText {
  margin: 24px 0;
}

.title {
  margin-bottom: 50px;
}