.connectionModal {
  padding: 24px;
  border: none;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.08);
  border-radius: 24px;
  width: 100%;
  max-width: 500px;
  overflow: hidden;
}

.modalContainer {
  margin: 16px;
}

.connectionModalTitle {
  margin-bottom: 16px;
  word-break: break-word;
  overflow: hidden;
  margin-top: 24px;
}

.connectionModalAddressContainer {
  position: relative;
  background: #F0F5FF;
  border-radius: 8px;
  padding: 12px 16px;
  margin-bottom: 24px;
}

.connectionModalCompanyTitle {
  margin-bottom: 8px;
}

.connectionModalLawInfo {
  font-family: 'Roboto', sans-serif;
  margin-bottom: 24px;
}

.modalCloseBtn {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;

  &:hover {
    background-color: transparent;
  }
}

.connectionModalButtons {
  display: flex;
  justify-content: flex-end;
  column-gap: 12px;
}

.successContainer {
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.identMB_m {
  margin-bottom: 24px;
}

.identMB_s {
  margin-bottom: 8px;
}

@media screen and (max-width: 640px) {
  .connectionModalTitle {
    font-size: 18px;
    line-height: 24px;
  }

  .modalCloseBtn {
    top: 0;
    right: 0;
  }

  .connectionModalAddressContainer {
    margin-bottom: 16px;
  }

  img.identMB_m {
    max-width: 48px;
  }

  .identMB_m {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 16px;
  }
}

.address {
  margin-top: 24px;
  background: #F0F5FF;
  width: 100%;
  text-align: left;
  padding: 12px 16px;
  border-radius: 8px;
}
