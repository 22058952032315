.container {
  padding: 0 32px;
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  height: 100%;

  &__link {
    font-family: Roboto;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
}

.navMenu {
  display: flex;
  column-gap: 24px;
}

.navContainer {
  margin-right: auto;
  margin-left: 114px;
  display: flex;
  align-self: stretch;
}

.navMenu__item {
  display: flex;
  align-items: center;
  position: relative;
}

.logo {
  padding: 24px;
  cursor: pointer;
}

.fio {
  padding: 24px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.footer {
  margin: auto 0 24px 24px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.navMenu__item:after {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 100%;
  height: 2px;

  background: #43BABB;
  border-radius: 2px 2px 0 0;
  opacity: 0;

  transition-property: opacity;
  transition-duration: 0.3s;
}

.active:after {
  opacity: 1;
}

.avatar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  vertical-align: center;

  cursor: pointer;

  width: 48px;
  height: 48px;
  background: #171917;
  border-radius: 40px;
  letter-spacing: -.1rem;

  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 40px;
  /* or 200% */

  text-align: center;

  /* content/action-on */

  color: #FFFFFF;
}

.mobileMenuContainer {
  display: flex;
}

.menu {
  /*  margin-top: 12px;*/
  min-width: 240px;
}


.menu__item {
  /*  margin: 0;
  padding: 0 16px;
  width: 100%;*/
}

.menuRef {
  display: block;
  width: 100%;
  height: 100%;
}

.mobileMenu {
  display: none;
}

@media screen and (max-width: 640px) {
  .logo {
    padding: 18px 0;
  }

  .logo img {
    height: 20px;
  }

  .menu__list {
    width: 100vw;
  }

  .avatar {
    width: 40px;
    height: 40px;

    font-size: 16px;
    line-height: 40px;
  }
}

.bgWhite {
  display: flex;
  width: 100%;
  max-width: 300px;
  background: #FFFFFF;
  border-radius: 24px;
  margin: 32px;
  // border: 1px solid darkgray;
}

.nav {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;

  &__itemMenu {
    display: flex;
    gap: 12px;
    align-items: center;
  }

  &__list {
    display: flex;
    flex-direction: column;
    margin-left: -1px;

    & li {
      padding: 10px 26px;
      border-left: 2px solid #893eec00;
      transition: border-color .5s ease-in-out;
      /* Плавный переход для всех свойств */
      cursor: pointer;
      /* Курсор в виде указателя */

      & p {
        transition: color .3s linear;
      }

      & p:hover {
        color: #893EEC;
      }

      &.active {


        & p {
          color: #893EEC;
        }
      }
    }
  }
}

.borderActiveMenu {
  position: absolute;
  height: 44px;
  width: 2px;
  background: #893EEC;
  transition: all .3s cubic-bezier(.53, .01, .59, 1.17);
}

@media screen and (max-width: 768px) {
  

  .fio {
    padding: 14px;
  }

  .borderActiveMenu {
    display: none;
  }

  .bgWhite {
    max-width: -webkit-fill-available;
    background: #ffffff00;
    margin: 0px;
    // border: 1px solid darkgray;
  }

  .mobileMenu {
    display: block;
  }

  .nav {
    display: flex;
    position: fixed;
    background: #FFFFFF;
    bottom: 0;
    z-index: 1;
    left: 0;
    overflow: hidden;
    border-radius: 24px 24px 0 0;

    &__list {
      flex-direction: row;
      justify-content: space-evenly;

      & li {
        padding: 10px;

      }
    }

    &__itemMenu {
      flex-direction: column;
      text-align: center;
      font-size: 10px;
      gap: 0;
    }
  }

  .footer {
    display: none;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    height: 100%;
    padding: 15px;

    &__link {
      font-family: Roboto;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }
  }
}
