.wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: 16px;
}

.item {
  display: flex;
  background-color: #fff;
  border-radius: 24px;
  overflow: hidden;
  padding: 15px;
  gap: 16px;
  align-items: flex-start;
  cursor: pointer;

  & img {
    width: 56px;
  }

  &__info {
    display: flex;
    flex-direction: column;
  }
}

.help {
  background-color: #fff;
  border-radius: 24px;
  overflow: hidden;
  padding: 15px;
  margin-top: 40px;
}

.list {
  margin-top: 40px;

  &__title {
    display: flex;
    gap: 18px;
    align-items: flex-start;
    // max-width: 530px;
  }

  &__number {
    background-color: #F1E8FF;
    border-radius: 8px;
    padding: 3px 12px;
    color: #893EEC;
  }

  &__content {
    display: flex;
    flex-direction: column;
    margin-left: 50px;
    gap: 15px;
    margin-top: 20px;
  }

  &__subTitle {
    gap: 10px;
    display: flex;
  }

  &__imgWrapper {
    padding: 20px 20px 0 20px;
    border-radius: 14px;
    background-color: #F6F6F6;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  &__imgWrpLong {
    width: 100%;
    max-width: 500px;

    & img {
      width: 100%;
      max-width: 100%;
    }
  }

  &__btnGroupe {
    display: flex;
    justify-content: center;
    gap: 10px;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }

  &__btn {
    // width: min-content;
    max-width: 225px;
    width: 100%;
    margin-bottom: 20px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    background-color: #893EEC;
  }

  &__img {
    width: min-content;
    max-width: 280px;
  }

  &__subList {
    display: flex;
    gap: 10px;
    align-items: center;
  }
}



@media screen and (max-width: 768px) {
  .permissions {
  
    &__title {
      font-size: 24px;
    }
  }

  .item {

    &__title {
      font-size: 20px;
    }
  }
}


@media screen and (max-width: 650px) {
  .tempImg {
    display: none;
  }

  .list {

    &__btnGroupe {
      justify-content: center;
    }

    &__content {
      margin-left: 0px;
    }
  }
}
