.custom_theme {
/*  --primary-color: #10002B;*/
  --color-content-primary: #10002B !important;
  --color-content-action: #8c63c5 !important;
  --color-background-action: #8A63C1 !important;
  --color-border-action: var(--color-content-action) !important;
  --color-background-action-light-hover: none !important;
  --button-hover-bg-color: none !important;
  --color-background-action-light-pressed: none !important;
  --color-background-action-hover: #865bc0 !important;
  --color-background-action-pressed: #8255be !important;
}